import React, { useState } from "react";
import "./styles.css";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import Bcal from "components/Bcal";
import { Col } from "react-bootstrap";


const Participate = (props) => {
  
  return (
    <div class="app-two">
      <NavBar value="participate" />

      <section className="row title-background participate " style={{background:"white"}} id="participate">
        <Col>
          <h1 className="main-page-title" style={{color:"rgb(36,118,19)", marginBottom:"100px"}}>
          Participate In Our Research!
          </h1>
          <p style={{ color: "black" }}>If you are interested in participating in any of our research, reach out to us at <a href="mailto:bcal@ualberta.ca">bcal@ualberta.ca</a></p>  
          </Col>
        <Bcal />
      </section>

      

      <Footer />
    </div>
  );
};

export default Participate;
