import Bcal from "components/Bcal";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import React from "react";
import { Col, Row } from "react-bootstrap";

const Wedo = () => {
  return (
    <>
      <NavBar value="whatwedo" />
      <section
        className="row title-background whatwedo"
        style={{ background: "white" }}
        id="whatwedo"
      >
        <Col>
          <h1
            className="main-page-title"
            style={{ color: "rgb(36,118,19)", marginBottom: "50px" }}
          >
            What We Do
          </h1>
        </Col>
        <Row style={{ marginBottom: "50px", color: "black" }}>
          <h2 style={{ marginBottom: "50px" }}>Publications</h2>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Featured Papers</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              1. Hodgetts, W.E. Scott, D.K. Maas, P. Westover, L. (2018).
              Development of a Novel Bone Conduction Verification Tool using a
              Surface Microphone: Validation with Percutaneous Bone Conduction
              Users. Ear and Hearing.
            </p>
            <p>
              2. Hodgetts, W.E., & Scollie, S.D. (2017). DSL Prescriptive
              Targets for Bone Conduction Devices: Adaptation and Comparison to
              Clinical Fittings.International Journal of Audiology.
            </p>
            <p>
              3. Hodgetts, W.E. Aalto, D. Ostevik, A. Cummine, J. (2018).
              Changing Hearing Performance and Sound Preference with Words and
              Expectations: Meaning Responses in Audiology.Ear and Hearing. Ear
              & Hearing 2019;40;615–620)
            </p>
            <p>
              4. Cummine, J., Cullum, A., Aalto, D., Sereda, T., Fleming, C.,
              Reed, A., Ostevik, A., Cashion-Dextrase, S., Jeffery, C. C., &
              Hodgetts, W. E. (2021). From lollipops to lidocaine: The need for
              a universal print-to-speech framework. Canadian Journal of
              Experimental Psychology/Revue canadienne de psychologie
              expérimentale, 75(3), 279–298.{" "}
              <a href=" https://doi.org/10.1037/cep0000257">
                https://doi.org/10.1037/cep000025
              </a>
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: "50px", color: "black" }}>
          <h4 style={{ marginBottom: "50px" }}>
            Most Significant Contributions
          </h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              <h5>2017/01</h5>
              Temporal autocorrelations and between subject heterogeniety in
              resting state functional connectivity This paper advances
              methodology with respect to functional connectivity approaches to
              analyzing brain imaging data. The approach is validated in a group
              on individuals with dyslexia and a typical reading group. The
              approach is rated as one of the top 3 methods for conducting
              functional connectivity in neuroimaging data.
            </p>
            <p>
              <h5>2017/02</h5>
              Graphical modelling of the reading network in skilled and impaired
              readers: The role of the basal ganglia in the print to speech
              network This contribution represents a submitted publication to
              journal of cognitive neuroscience that 
              1) outlines the neural
              basis of the reading network in both skilled and impaired readers,
              2) demonstrates the effectiveness of the functional connectivity
              approach to studying brain networks and 
              3) advances models of
              written communication by providing novel ideas about the role of
              speech production in reading.
            </p>
            <p>
              <h5>2015/11</h5>
              Understanding the role of speech production in reading: Evidence
              for a print-to-speech network using graphical analysis This paper
              provides a framework for understanding the role of speech
              production in reading. It is a first demonstration of the
              application of graphical modelling analysis techniques to
              understanding print.
            </p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Journal Articles</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              1. Holmes, M., Aalto, D., Cummine, J. (2023). Perils & pitfalls of
              fNIRS: A systematic investigation. Frontiers in Human
              Neuroscience.
            </p>
            <p>
              2. Jacqueline Cummine, Julia Craig, Cory McKenzie, Mikayla Toal,
              Emilie Lefebvre, Alexis Baird, Meghan Edgson, Amberley V. Ostevik,
              Kulpreet Cheema, William Hodgetts. (2023). Exploring the impact of
              non-linguistic factors on single word recognition in skilled adult
              readers. Canadian Psychology.
            </p>
            <p>
              3. Jacqueline Cummine, Dima Alsaigh, Talah Hasanni, Mitchell
              Holmes, Kulpreet Cheema, Truc Huynh, Sarah Saju, Torrey Loucks,
              Daniel Aalto. (2023). The role of the IFG and STG in overt vs.
              covert speech production: An fNIRS approach. Brain and Language.
            </p>
            <p>
              4. Cassandra Cowan, Kathleen Jones, Amberley V. Ostevik, Sara Al
              Souqi, William Hodgetts, Jacqueline Cummine. (2023). An invisible
              disability: A practical guide to capturing the consequences of
              fluctuating hearing in school-age children. Canadian Journal of
              Speech Language Pathology and Audiology.
            </p>
            <p>
              5. Cummine, J., Ostevik, A., Song, Q, Kim, Y., Hodgetts, W.
              (2023). Tissue classification following bone anchored hearing
              implant surgery: A machine learning approach to monitoring skin
              response.Otology & Neurotology
            </p>
            <p>
              6. Cummine, Jacqueline, Ngo, Tiffany, Nisbett, Kelly. (2023).
              Structural lateralization of subcortical regions differentiates
              skilled and impaired readers: Towards a better understanding of
              the role of the caudate, putamen and thalamus in reading.
              Neuroscience.
            </p>
            <p>
              6. Cummine, Jacqueline, Ngo, Tiffany, Nisbett, Kelly. (2023).
              Structural lateralization of subcortical regions differentiates
              skilled and impaired readers: Towards a better understanding of
              the role of the caudate, putamen and thalamus in reading.
              Neuroscience.
            </p>

            <p>
              7. Trelani MILBURN, Leah AUCH, Alexandra HENDERSON, Anka CHAN, &
              Jacqueline CUMMINE. (2023). Comparison of the Efficiency and
              Reliability for Two Methods of Coding Language Samples: SALT
              Versus The ObserverXT. International Journal of Language &
              Communication Disorders
            </p>
            <p>
              8. Kelly Nisbet, Avary Kostiw, Thi Kim Truc Huynh, Sukhmani Kaur
              Saggu, Dev Patel, Jacqueline Cummine. (2023). Differential grey
              matter structure of the pars orbitalis, triangularis and
              opercularis in individuals with dyslexia: A volumetric asymmetry
              study. Journal of Neuroscience Research
            </p>
            <p>
              9. Jacqueline Cummine *, Amberley Ostevik, Kulpreet Cheema, Angela
              Cullum. (2023). Adult Learner Perspectives on Skill- and
              Life-Based Outcomes FollowingLiteracy Remediation. Social
              Sciences.
            </p>
            <p>
              10. Angela Cullum, Jaylyn Loewen, Jacqueline Cummine. (2023).
              Aphantasia, memory, and mood: A mixed-methods analysis. Cortex.
            </p>
            <p>
              11. Alesha Reed, *Truc Huynh, Amberley V. Ostevik, Kulpreet
              Cheema, Sarah Sweneya, Julia Craig, Jacqueline Cummine. (2023).
              Phonological, Orthographic and Morphological Skills are Related to
              Structural Properties of Ventral and Motor White Matter Pathways
              In Skilled and Impaired Readers. Journal of Neurolinguistics.
            </p>
            <p>
              12. Kulpreet Cheema, Cassidy Fleming, Julia Craig, William E.
              Hodgetts, and Jacqueline Cummine. (2022). Reading and Spelling
              Profiles of Adults with and without Dyslexia: Phonological,
              Orthographic and Morphological Considerations. Dyslexia.
            </p>
            <p>
              13. Alexandra S. Budd, Thi K. T. Huynh, Peter Seres, Christian
              Beaulieu, SusanArmijo-Olivo, and JacquelineCummine. (2022). White
              Matter Diffusion Properties inChronic TemporomandibularDisorders:
              An Exploratory Analysis. Frontiers in Pain Research.
            </p>
            <p>
              14. Kulpreet Cheema, Sarah Sweneya, Julia Craig, Truc Huynh,
              Amberley V. Ostevik, Alesha Reed, Jacqueline Cummine. (2022). An
              investigation of white matter properties as they relate to
              spelling behaviour in skilled and impaired readers.
              Neuropsychological Rehabilitation. Published
            </p>
            <p>
              15. Samira Zabian, Dr. Cassidy Fleming, Amberly V. Ostevik, Dr.
              Jacqueline Cummine. (2022). The Crisis Study: COVID-19 & Reading
              Impairments - Survey Investigating Socializing. Papers of 4th
              Canadian International Conference on Humanities & Social Sciences
              2022 (978-1-988652-50-4).
            </p>
            <p>
              16. *Cheema, Kulpreet; Hodgetts, Williams; Cummine, Jacqueline.
              (2021). Is the letter ‘t’ in the word ‘gourmet’? A
              characterization of spelling networks between skilled and impaired
              readers. NeuroSci.
            </p>
            <p>
              17. William Hodgetts 1,2,3, Qi Song 4, Xinyue Xiang 4 and
              Jacqueline Cummine 1,3,*. (2021). On a Vector towards a Novel
              Hearing Aid Feature: What Can We Learn from Modern Family, Voice
              Classification and Deep Learning Algorithms. Applied Sciences.
            </p>
            <p>
              18. Jacqueline Cummine, Thi Kim Truc Huynh, Angela Cullum,
              Amberley Ostevik, William Hodgetts. (2021). Chew on this! Oral
              stereognosis predicts visual word recognition in typical adults.
              Current Psychology.
            </p>
            <p>
              19. Alesha Reed, Jacqueline Cummine, Neesha Bhat, Shivraj Jhala,
              Reyhaneh Bakhtiari, and Carol A. Boliek. (2021). Changes in
              Intermuscular Coherence as a Function of Age and Phase of Speech
              Production During an Overt Reading Task. Motor control.
            </p>
            <p>
              20. Cheema, K., Westover, L., Ostevik, A., Hodgetts, W., &
              Cummine, J. (2021). Resting-state networks and reading in adults
              with and without reading impairments.Journal of Neurolinguistics.
            </p>
            <p>
              21. Jacqueline Cummine, *Angela Cullum, Daniel Aalto, *Tyson
              Sereda, *Cassidy Fleming, *Alesha Reed, Amberley Ostevik, Caroline
              Jeffery, & William E. Hodgetts. (2021). From Lollipops to
              Lidocaine: Changes in Somatosensory Feedback Modifies Silent Word
              Recognition Performance in Adults. Canadian Journal of
              Experimental Psychology.
            </p>
            <p>
              22. Craig St. Jean, Jacqueline Cummine, Gurjit Singh, William
              Hodgetts. (2020). Be Part of the Conversation: Audiology Messaging
              During a Hearing Screening. Ear and Hearing.
            </p>
            <p>
              23. Cassidy Fleming1, Teresa Paslawski1,2, Torrey Loucks1,3,4,
              Angela Cullum1, Daniel Aalto1,2, Amber Ostevik1, William E.
              Hodgetts1,5, & Jacqueline Cummine. (2020). "An Investigation of
              the Impact of Somatosensory Feedback on Reading Performance in
              Children with Childhood Apraxia of Speech: A Case-Control Series".
              Journal of Communication Disorders
            </p>
            <p>
              24. Cory McKenzie, William Hodgetts, Amber Ostevik, Jacqueline
              Cummine. (2020). Listen Before You Drive: The Effect of Voice
              Familiarity on Listening Comprehension and Driving Performance.
              International Journal of Audiology
            </p>
            <p>
              25. Jacqueline Cummine, Miya Villarena, Taylor Onysk, Joseph
              Devlin. (2020). A study of null effects for the use of
              transcranial direct currentstimulation (tDCS) plus
              skill-basedtraining in adults with and without reading impairment.
              Neurobiology of Language.
            </p>
            <p>
              26. Reed, Alesha; Cummine, Jacqueline; *Bhat, Neesha; Jhala,
              Shivraj; Boliek, Carol A. PhD. (2020). "Measuring Intermuscular
              Coherence of Speech Motor Control in Younger and Older Adults on
              an Overt Reading Task". Motor Control.
            </p>
            <p>
              27. Lindsey Westover, Ph.D. Amberley V Ostevik, M.Sc. Daniel
              Aalto, Ph.D Jacqueline Cummine, Ph,D William E Hodgetts, Ph.D.
              (2020). Evaluation of Word Recognition and Word Recall with Bone
              Conduction Devices: Do Directional Microphones Free up Cognitive
              Resources?. International Journal for Audiology.
            </p>
            <p>
              28. Amberley V. Ostevik *Lindsey Westover Haley Gynane Jordan
              Herst Jacqueline Cummine William E. Hodgetts. (2018). (2019).
              Comparison of Health Insurance Coverage for Hearing Aids and Other
              Services in Alberta.Healthcare Policy.
            </p>
            <p>
              29. *Angela Cullum, William Hodgetts, Trelani Chapman, Jacqueline
              Cummine. (2019). Cerebellar Activation During Reading Tasks:
              Exploring the Dichotomy between Motor vs. Language Functions in
              Adults of Varying Reading Proficiency. The Cerebellum
            </p>
            <p>
              30. *Julia Craig, Amberley V. Ostevik, Lindsey Westover, Bill
              Hodgetts, Jacqueline Cummine. (2019). To Go or Not to Go:
              Exploring brain activation during response inhibition reading
              tasks. Spectrum.
            </p>
            <p>
              31. Hodgetts, W.E. Aalto, D. Ostevik, A. Cummine, J. (2018).
              Meaning Responses in Audiology: Changing Hearing Performance and
              Sound Preference with Words and Expectations.Ear and Hearing.
              epub: 1-16.
            </p>
            <p>
              32. *Kulpreet Cheema Jacqueline Cummine. (2018). The Relationship
              Between White Matter and Reading Acquisition, Refinement and
              Maintenance. Developmental Neuroscience. NA: NA
            </p>
            <p>
              33. Jacqueline Cummine, Daniel Aalto, Amberley Ostevik, Kulpreet
              Cheema, William Hodgetts. (2018). To name or not to name: The role
              of response inhibition in reading. Journal of Psycholinguistics.
              47(5): 999-1014.
            </p>
            <p>
              34. Brea Chouinard, Joanne Volden, John Hollinger, Jacqueline
              Cummine. (2018). Processing Spoken and Written
              Metaphors:Comparison Using the Metaphor Interference Effect.
              Discourse Processes. NA: NA
            </p>
            <p>
              35. Carol A Boliek, Reyhaneh Bakhatiari, Lauren Pedersen, *Julia R
              Esch, and Jacqueline Cummine. (2018). Differential Cortical
              Control ofChest Wall Muscles during Pressure- and Volume-Related
              Expiratory Tasks and theEffects of Acute Expiratory Threshold
              Loading . Motor Control. NA: NA
            </p>
            <p>
              36. Jacqueline Cummine, Carol A. Boliek, Tessa McKibben, Aamn
              Jaswal, Marc F. Joanisse. (2018). Transcranial direct current
              stimulation (tDCS) selectively modulates semantic information
              during reading. Brain and Language. n/a: 0.
            </p>
            <p>
              37. Brea Chouinard Joanne Volden Ivor Cribben Jacqueline Cummine.
              (2017). Neurological evaluation of the selection stage of metaphor
              comprehension in individuals with and without autism spectrum
              disorder. Neuroscience. Unknown: Unknown
            </p>
            <p>
              38. Reyhaneh Bakhtiari, Jacqueline Cummine, *Alesha Reed, Cynthia
              M. Fox, Ivor Cribben, & Carol A. Boliek. (2017). Changes in brain
              activity following intensive voice treatment in children with
              cerebral palsy. Human Brain Mapping. Unknown: Unknown
            </p>
            <p>
              39. *Kulpreet Cheema *Nicole Lantz Jacqueline Cummine. (2017).
              Exploring the role of subcortical structures in developmental
              reading impairments: Evidence for subgroups differentiated by
              caudate activity. NeuroReport. Unknown: Unknown
            </p>
            <p>
              40. *Claire Rollans and Jacqueline Cummine. (2017). One tract, two
              tract, old tract, new tract: a pilot study of the structural and
              functional differentiation of the inferior fronto-occipital
              fasciculus. Journal of Neurolinguistics. NA: NA
            </p>
            <p>
              41. *Claire Rollans, George K. Georgiou, *Kulpreet Cheema
              Jacqueline Cummine. (2017). The Role of White Matter
              Microstructure in Rapid Naming: A Diffusion Tensor Imaging Study.
              Neuroscience. Unknown: Unknown
            </p>
            <p>
              42. Alesha Reed, Jacqueline Cummine Reyhaneh Bakhtiari, Cynthia M
              Fox, Carol A Boliek. (2017). Changes in white matter integrity
              following intensive voice treatment (LSVT LOUD) in children with
              cerebral palsy and motor speech disorders. Developmental
              Neuroscience. Unknown: Unknown.
            </p>
            <p>
              43. Jacqueline Cummine, *Wahab Hanif, *Inna Dymouriak-Tymashov,
              *Kavya Anchuri, *Stephanie Chiu, Carol Boliek. (2017). The role of
              the supplementary motor region in overt speech production:
              Evidence for differential processing in SMA-proper and pre-SMA as
              a function of task demands. Brain Topography. 30: 579-591.
            </p>
            <p>
              44. Mark Fiecas, Ivor Cribben, Reyhaneh Bahktiari, Jacqueline
              Cummine. (2017). Temporal autocorrelation and between-subject
              heterogeneity in resting-state functional connectivity.NeuroImage.
              149: 256-266.
            </p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Prescription and Verification of Bone Conduction Devices</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              1. Ostevik, A. V, Hill-Feltham, P., Johansson, M. L., McKinnon, B.
              J., Monksfield, P., Sockalingam, R., …Hodgetts, W.E. (2021).
              Psychosocial outcome measures for conductive and mixed hearing
              loss treatment: An overview of the relevant literature.
              International Journal of Audiology, 0(0), 1–16.{" "}
              <a href="https://doi.org/10.1080/14992027.2021.1872805">
                https://doi.org/10.1080/14992027.2021.1872805
              </a>
            </p>
            <p>
              2. Ad Snik, Hannes Maier, William Hodgetts, Martin Kompis, Griet
              Mertens, Paul van de Heyning, Thomas Lenarz, Arjan Bosman. (2019).
              Efficacy of implantable hearing devices for patients with
              conductive or mixed hearing loss depends on the implant centre;
              the development of a fitting rule.Otology and Neurotology. 40(4):
              430-435.
            </p>
            <p>
              3. Hill-Feltham, P. R., Johansson, M. L., Hodgetts, W. E.,
              Ostevik, A. V., McKinnon, B. J., Monksfield, P., … Tysome, J. R.
              (2020). Hearing outcome measures for conductive and mixed hearing
              loss treatment in adults: A scoping review. International Journal
              of Audiology, 0(0), 1–7.{" "}
              <a href=" https://doi.org/10.1080/14992027.2020.1820087">
                https://doi.org/10.1080/14992027.2020.1820087
              </a>
            </p>
            <p>
              4. *Westover, L. Faulkner, G. Flores-Mir, C. Hodgetts, W.E.
              Raboud, D. (2019). Non-Invasive Evaluation of Periodontal Ligament
              Stiffness During Orthodontic Tooth Movement. Angle Orthodontist.
              89(2): 228-234.
            </p>
            <p>
              5. *Westover, L., Faulkner, G., Hodgetts, W.E., & Raboud, D.
              (2018). Comparison of implant stability measurement devices for
              bone anchored hearing aid systems. Journal of Prosthetic
              Dentistry. 119(1): 178-184.
            </p>
            <p>
              6. *Lindsey Westover Gary Faulkner Carlos Flores-Mir William
              Hodgetts Don Raboud. (2018). Application of the Advanced System
              for Implant Stability Testing (ASIST) to Natural Teeth for
              Noninvasive Evaluation of the Tooth Root Interface. Journal of
              Biomechanics. 69: 129-137.
            </p>
            <p>
              7. *Lindsey Westover Gary Faulkner William Hodgetts Fraaz Kamal
              Edmond Lou Don Raboud. (2018). Longitudinal Evaluation of Bone
              Anchored Hearing Aid Implant Stability Using the Advanced System
              for Implant Stability Testing (ASIST). Otology and Neurotology.
            </p>
            <p>
              8. Scollie, S., Hodgetts, W., & Pumford, J. (2018). DSL for bone
              anchored hearing devices: Prescriptive targets and verification
              solutions.Audiology Online. (22962)
            </p>
            <p>
              9. Hodgetts, W.E. Scott, D.K. Maas, P. Westover, L. (2018).
              Development of a Novel Bone Conduction Verification Tool using a
              Surface Microphone: Validation with Percutaneous Bone Conduction
              Users. Ear and Hearing.
            </p>
            <p>
              10. *Allison R. Mackey, William E. Hodgetts, & Susan A. Small.
              (2017). Maturation of bone-conduction transcranial attenuation
              using a measure of sound pressure in the ear canal. International
              Journal of Audiology. 57(4): 283-290.
            </p>
            <p>
              11. Hodgetts, W.E., & Scollie, S.D. (2017). DSL Prescriptive
              Targets for Bone Conduction Devices: Adaptation and Comparison to
              Clinical Fittings.International Journal of Audiology.
            </p>
            <p>
              12. Scollie & Hodgetts (2017). Desired Sensation Level - Bone
              Conduction: Our prescription for Bone Conduction Output is
              licensed by three manufacturers.{" "}
            </p>
            <p>
              13. Hodgetts, W.E. & Hakansson, B.E.V. (Co-inventors; 2007).
              Fitting and Verification Procedures for Direct Bone Conduction
              Hearing Devices. Swedish Patent Number: 0702629-7
            </p>
            <p>
              14. *Mackey, A., Hodgetts, W.E., Scott, D. & Small, S.A. (2016).
              Maturation of mechanical impedance of the skin-covered skull:
              Implications for soft band bone-anchored hearing systems fitted in
              infants and young children. Ear and Hearing. 37(4): 210-223.
            </p>
            <p>
              15. *Westover, L., Faulkner, G., Hodgetts, W.E., & Raboud, D.
              (2016). Advanced System for Implant Stability Testing
              (ASIST).Journal of Biomechanics.
            </p>
            <p>
              16. J. R. Tysome · P. Hill-Feltham · W. E. Hodgetts · B. J.
              McKinnon · P. Monksfield · R. Sockalingham · M. L. Johansson · A.
              F. Snik. (2015). The Auditory Rehabilitation Outcomes Network: an
              international initiative to develop core sets of patient-centred
              outcome measures to assess interventions for hearing loss.Clinical
              Otolaryngology. 40(12): 512-515.
            </p>
            <p>
              17. Ostevik AV, Caissie R, Verge J, Gulliver M, Hodgetts WE.
              (2013). Are open-fit hearing aids a possible alternative to
              bone-anchored hearing devices in patients with mild to severe
              hearing loss? A preliminary trial. Audiology Research, 3 (2),
              10-15. .
            </p>
            <p>
              18. Szudek, J., Ostevik, A.V., Dziegielewski, P., Robinson-Anagor,
              J., Gomaa, N., Hodgetts, B. & Ho, A. (2012). Can uHearTM me now?
              Validation of an iPod-based hearing loss screening test. Journal
              of Otolaryngology - Head & Neck Surgery, 41, 78-84.
            </p>
            <p>
              19. Yu, J., Ostevik, A.V., Hodgetts, W.E., & Ho, A. (2011).
              Automated hearing tests: Applying the Otogram TM to patients who
              are difficult to test. Journal of Otolaryngology - Head & Neck
              Surgery, 40, 5, 376-383.
            </p>
            <p>
              20. Hodgetts, W.E., Hagler, P., Hakansson, B.E.V., & Soli, S.
              (2011). Technology-Limited and Patient-Derived Versus
              Audibility-Derived Fittings in BAHA Users: An Efficacy Study. Ear
              and Hearing, 32, 1, 31-39.{" "}
            </p>
            <p>
              21. Hodgetts, W.E., Hakansson, B.E.V., Hagler, P., & Soli, S.
              (2010). A Comparison of Three Approaches to Verifying Aided Baha
              Output. International Journal of Audiology, 49, 4, 286-295.
            </p>
            <p>
              22. Dumper, J.D., Hodgetts, W.E., Liu, R., & Brandner, N. (2009).
              Indications for BAHA®: A Functional Outcomes Study. Journal of
              Otolaryngology-Head & Neck Surgery, 38, 1, 96-105.
            </p>
            <p>
              23. Hodgetts, W.E., Scollie, S.D. & Swain, R. (2006). The BAHA®
              Softband: Effects of the Applied Contact Force and Volume Control
              Setting on Output Force Level. International Journal of Audiology,
              45, 301-308.
            </p>
            <p>
              24. Snik, A.F.M. Mylanus, E.A.M., Cremers, C.W.R.J., Wolfaardt,
              J., Hodgetts, W.E., Somers, T., Proops, D.W., Tjellström, A.,
              Wazen, J.J., & Niparko, J. (2005). Consensus statements on the
              BAHA system: Where do we stand at present? Annals of Otology,
              Rhinology, and Laryngology, 114 (12), Suppl 195:1-12.
            </p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Psychology and Outcomes in Care</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              1. Miyagishima, R., Hopper, T., Hodgetts, W.E., Soos, B.,
              Williamson, T., & Drummond, Neil (2021). Development of a Case
              Definition for Hearing Loss in Community-Based Older Adults: a
              cross-sectional validation study. Canadian Medical Association
              Journal, 9 (3) E796-E801; DOI: 10.9778/cmajo.20200267
            </p>
            <p>
              2. *McKenzie, C., Hodgetts, W.E., Ostevik, A.V., & Cummine, J.
              (2020). Listen before you drive: the effect of voice familiarity
              on listening comprehension and driving performance. International
              Journal of Audiology, 60, 621 - 628..
            </p>
            <p>
              3. *Westover L, Ostevik A, Aalto D, Cummine J, Hodgetts WE.
              Evaluation of word recognition and word recall with bone
              conduction devices: do directional microphones free up cognitive
              resources? Int J Audiol. 2020 May;59(5):367-373. doi:
              10.1080/14992027.2019.1708983. Epub 2020 Mar 10. PMID: 32151192.
            </p>
            <p>
              4. Amberley V. Ostevik Lindsey Westover *Haley Gynane *Jordan
              Herst Jacqueline Cummine William E. Hodgetts. (2019). Comparison
              of Health Insurance Coverage for Hearing Aids and Other Services
              in Alberta. Healthcare policy, 15(2),{" "}
            </p>
            <p>
              5. Hodgetts, W.E. Aalto, D. Ostevik, A. Cummine, J. (2018).
              Changing Hearing Performance and Sound Preference with Words and
              Expectations: Meaning Responses in Audiology.Ear and Hearing. Ear
              & Hearing 2019;40;615–620)
            </p>
            <p>
              6. *Gabriela Constantinescu Jana Rieger William Hodgetts. (2018).
              Mobile Health Design and Development in the Academic Setting.
              International Journal of Medical Informatics.
            </p>
            <p>
              7. *Constantinescu, G., *Kuffel, K., Aalto, D., Hodgetts, B.,
              Rieger, J. (2017). Evaluation of an automated swallow-detection
              algorithm using visual biofeedback in healthy adults and head and
              neck cancer survivors. Dysphagia.
            </p>
            <p>
              8. *Gabriela Constantinescu, MSc-SLP, Jana Rieger, PhD, Kerry
              Mummery, PhD, William Hodgetts, PhD. (2017). Flow and Grit by
              Design: Exploring gamification in facilitating adherence to
              swallowing therapy for patients with head and neck cancer.
              American Journal of Speech-Language Pathology.
            </p>
            <p>
              9. *Constantinescu, G., Loewen, I., King, B., Brodt., C.,
              Hodgetts, W.E., Rieger, J. (2017). Designing a mobile health
              application for patients with dysphagia following head and neck
              cancer. JMIR mHealth and uHealth.4(1): 1-10.
            </p>
            <p>
              10. *Constantinescu, G., Hodgetts, W.E., Scott, D., *Kuffel, K.,
              King, B. Brodt, C. & Rieger, J. (2017). Electromyography and
              mechanomyography signals during swallowing in healthy adults and
              head and neck cancer survivors. Dysphagia. 32(1): 90-103.
            </p>
            <p>
              11. Hopper, T., Slaughter, S., Hodgetts, W.E., Ostevik, A., &
              Ickert, C. (2016). Hearing loss and cognitive communication test
              performance among long-term care residents with dementia: Effects
              of amplification. Journal of Speech, Language, and Hearing
              Research.
            </p>
            <p>
              12. *Constantinescu, G. King, B., McMahon, M. Brodt, C., Hodgetts,
              W., Rieger, J. (2016). Designing for patients: Using a cultural
              probe in the development of a mobile health device and application
              for swallowing therapy in head and neck cancer patients.
              University of Alberta Health Sciences Journal (UAHSJ). 12(1): 1-6.
            </p>
            <p>
              13. Logan, H., Wolfaardt, J., Boulanger, P., Hodgetts, W.E.,
              Seikaly, H. (2013). Exploratory benchtop study evaluating the use
              of surgical design and simulation in fibula free flap mandibular
              reconstruction. Journal of Otolaryngology - Head and Neck Surgery
              2013, 42:42.{" "}
            </p>
            <p>
              14. Logan, H., Wolfaardt, J., Boulanger, P., Hodgetts, W.E.,
              Seikaly, H. (2013). Pilot Study: Evaluation of the use of the
              convergent interview technique in understanding the perception of
              surgical design and simulation. Journal of Otolaryngology - Head
              and Neck Surgery, 42:40.
            </p>
            <p>
              15. Logan, H., Wolfaardt, J., Seikaly, H., Hodgetts, W.E., &
              Boulanger, P. (2013). Evaluation of the Accuracy of Cone Beam
              Computerized Tomography (CBCT) Medical Imaging Technology in Head
              and Neck Reconstruction. Journal of Otolaryngology - Head and Neck
              Surgery 42:25.{" "}
            </p>
            <p>
              16. Hodgetts, S. & Hodgetts, W.E. (2007) Somatosensory Stimulation
              Interventions for Children with Autism: Literature Review and
              Clinical Considerations. Canadian Journal of Occupational Therapy,
              74(5), 393-400.
            </p>
            <p>
              17. Hodgetts, W.E., Hagler, P. & Thompson, S.L. (2006). Exploring
              the Use of Factor Analysis to Determine the Relevant Dimensions of
              Outcome for a Given Population in Rehabilitation Science: A
              Tutorial. Journal of Speech-Language Pathology and Audiology,
              30(2), 132-141.
            </p>
            <p>
              18. Jamieson, D.G., Kranjc, G., Yu, K. & Hodgetts, W.E. (2004).
              Speech Intelligibility of Young School-Aged Children in the
              Presence of Real-Life Classroom Noise. Journal of the American
              Academy of Audiology, 15(7), 508-517.
            </p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Psychosocial Consequences of Abnormal Ears</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>1. Work in Progress with L. McHutchion</p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Imaging (fNIRS and fMRI) with Hearing Devices</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>1. Work in progress with J. Cummine and K. Cheema.</p>
          </Col>
        </Row>

        <Row
          style={{ color: "black", marginBottom: "50px" }}
          className="text-center"
        >
          <h4>Understanding and Managing Adult Literacy</h4>
          <Col lg={8} style={{ margin: "auto" }}>
            <p>
              1. Cummine, J., Cullum, A., Aalto, D., Sereda, T., Fleming, C.,
              Reed, A., Ostevik, A., Cashion-Dextrase, S., Jeffery, C. C., &
              Hodgetts, W. E. (2021). From lollipops to lidocaine: The need for
              a universal print-to-speech framework. Canadian Journal of
              Experimental Psychology/Revue canadienne de psychologie
              expérimentale, 75(3), 279–298.{" "}
              <a href="https://doi.org/10.1037/cep0000257" target="__blank">
                https://doi.org/10.1037/cep0000257
              </a>
            </p>
            <p>
              2. Cheema,K.;Hodgetts, W.E.; Cummine, J. Is the Letter ‘t’ in the
              Word ‘gourmet’? Disruption in Task-Evoked Connectivity Networks in
              Adults with Impaired Literacy Skills. NeuroSci 2021, 2, 75–94.{" "}
              <a
                href="https://doi.org/10.3390/neurosci 2010005"
                target="__blank"
              >
                https://doi.org/10.3390/neurosci 2010005
              </a>{" "}
            </p>
            <p>
              3. Kulpreet Cheema, Amberley V. Ostevik, Lindsey Westover, William
              E. Hodgetts, Jacqueline Cummine, Resting-state networks and
              reading in adults with and without reading impairments, Journal of
              Neurolinguistics, Volume 60, 2021, 101016, ISSN 0911-6044,{" "}
              <a
                href="https://doi.org/10.1016/j.jneuroling.2021.101016"
                target="__blank"
              >
                https://doi.org/10.1016/j.jneuroling.2021.101016
              </a>
              .
            </p>
            <p>
              4. Cummine, J., Huynh, T., Cullum, A., Ostevik, A., Hodgetts. W.
              (2021). Chew on this! Oral stereognosis predicts visual word
              recognition in typical adults. Current Psychology. Curr Psychol
              (2021).{" "}
              <a
                href="https://doi.org/10.1007/s12144-021-01647-1"
                target="__blank"
              >
                https://doi.org/10.1007/s12144-021-01647-1
              </a>
            </p>
            <p>
              5. *Cullum, A. Hodgetts, WE., Milburn, TF, & Cummine, J (2019).
              Cerebellar Activation During Reading Tasks: Exploring the
              Dichotomy between Motor vs. Language Functions in Adults of
              Varying Reading Proficiency. The Cerebellum. : 1-17.{" "}
              <a href="http://dx.doi.org/10.1007/s12311-019-01024-6">
                http://dx.doi.org/10.1007/s12311-019-01024-6
              </a>
            </p>
            <p>
              6. *Julia Craig, Amberley V. Ostevik, Lindsey Westover, William
              Hodgetts, Jacqueline Cummine. (2019). To Go or Not to Go:
              Exploring brain activation during response inhibition reading
              tasks. Spectrum. 3, 2019
            </p>
            <p>
              7. Jacqueline Cummine, Daniel Aalto, Amberley Ostevik, *Kulpreet
              Cheema, William Hodgetts. (2018). “To name or not to name: That is
              the question”: The role of response inhibition in reading. Journal
              of Psycholinguistic Research.
            </p>
          </Col>
        </Row>



        <Bcal />
      </section>
      <Footer />
    </>
  );
};

export default Wedo;
