import React from "react";
import { Row, Col } from "react-bootstrap";
import bcal from "../assets/img/bcal-logo.jpg";

const Bcal = () => {
  return (
    <Row className="text-center">
      <Col>
        <a href="mailto:bcal@ualberta.ca">
          <img style={{ width: "200px" }} src={bcal} alt="BCAL" />
          <p>Email Us</p>
        </a>
      </Col>
    </Row>
  );
};

export default Bcal;
