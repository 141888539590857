import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export const Header = () => {
  // state whether the link is active or not
  const [active, setActive] = useState(null);

  const handleActive = (linkName) => {
    console.log(linkName);
    setActive(linkName);
  };

  return (
    <div className="header">
      <div className="header-logo">
        <img
          className="logo-image"
          src={require("../assets/images/bcal-logo-removebg-preview-1.png")}
          alt="logo"
        />
        <div className="logo-text">Rehab Science</div>
      </div>
      <button
        className={`header-home-button ${active === "home" ? "active" : ""}`}
        onClick={() => handleActive("home")}
      >
        <Link to="/">home</Link>
      </button>
      <button
        className={`header-evaluation-button ${
          active === "evaluation" ? "active" : ""
        }`}
        onClick={() => handleActive("evaluation")}
      >
        <Link to="/evaluation">evaluation</Link>
      </button>
      <div className="header-research-button">research</div>
      <div className="team">team</div>
    </div>
  );
};
