import "./styles.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Backdrop, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import logo2 from "../../assets/img/ua-logo.svg";
import NavBar from "components/NavBar";
import Footer from "components/Footer";

const GUIUser = () => {
  const [open, setOpen] = useState(false);
  const [currCollection, setCurrCollection] = useState(null);
  const history = useHistory();

  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  useEffect(async () => {
    document.title = "Rehab Science";
    updateLayer({ id: "1" });
  }, []);

  const onAppClick = (app) => {
    setOpen(true);
    const appName = app.replace(/ /g, "");

    setTimeout(() => {
      setOpen(false);
      history.push(`/gui/${appName}`);
    }, 1500);
  };

  const updateLayer = async (ele) => {
    const doc = await axios.get("/api/gui/collection/" + ele.id);
    setCurrCollection(doc.data);
  };

  const handleBack = () => {
    updateLayer({ id: currCollection.prevCollection });
  };

  //styles
  const rowStyle = {
    display: "block",
    maxWidth: "100%",
    color: "white",
    margin: "0 auto",
  };

  const cardStyles = ["first", "second", "third"];

  return (
    <div class="app-two">
      <div className="col components-card text-center">
        <div className="row" style={rowStyle}>
          {currCollection?.list.map((obj, i) => {
            return (
              <div
                id={`card-${i}`}
                className={`eval-button`}
                onClick={
                  obj.type === "app"
                    ? () => onAppClick(obj.name)
                    : () => updateLayer(obj)
                }
              >
                <h2>{obj.name}</h2>
                <h4>{obj.type}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GUIUser;
