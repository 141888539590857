import Bcal from "components/Bcal";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Mitacs from "../assets/img/mitacs.png";

const Join = () => {
  return (
    <>
      <NavBar value="join" />
      <section className="row title-background join" style={{background:"white"}} id="join">
        <Col>
          <h1 className="main-page-title" style={{color:"rgb(36,118,19)", marginBottom:"100px"}}>
          Join Our Team
          </h1>
          </Col>
          <Row style={{marginBottom:"100px", color:"black"}}>
            <Col sm={6}>
              <img className="img-fluid" src={Mitacs} alt="Mitacs Logo" />
              <h3>Masters Student</h3>
              <p>2 years - open</p>
            </Col>
            <Col sm={6}>
            <img className="img-fluid" src={Mitacs} alt="Mitacs Logo" />
              <h3>Post-Doctoral Fellow</h3>
              <p>2 years - Starting 2023</p>
            </Col>
          </Row>
        <Bcal />
      </section>
      <Footer />
    </>
  );
};

export default Join;
