import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import bcd from "../assets/img/bcd.jpg"
import msgAud from "../assets/img/image.jpg"
import outcome from "../assets/img/maginify.jpg"
import ear from "../assets/img/ear.jpg"
import imaging from "../assets/img/imaging.jpg"
import literacy from "../assets/img/literacy.jpg"
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import Bcal from "components/Bcal";



const Ideas = () => {

  return (
    <div class="app-two">
     <NavBar value="ideas" />

      <section className="row title-background ideas" style={{background:"white"}} id="ideas">
        <div className="col">
          <h1 className="main-page-title" style={{color:"rgb(36,118,19)", marginBottom: "100px"}}>
          These Are Our Ideas
          </h1>
        </div>
        <Container >
        <Row style={{color:"black", marginBottom: "50px"}} >
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={bcd} alt="Prescription and Verification of Bone Conduction Devices" />
                <h2>Prescription and Verification of Bone Conduction Devices</h2>
                <p>Here the primary goals are to  1) prescribe the best settings for bone conduction devices on an individual basis, and 2) provide clinicians with the right tools to verify that the device is matching the prescription.</p>
          </Col>
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={msgAud} alt="Messing in Audiology" />
                <h2>Messaging in Audiology</h2>
                <p>Uptake of hearing care services and adherence to these service is lower than it should be. Only 1 in 5 people who need a hearing aid is actually wearing one. Here we explore ways to better understand how we might improve upon this situation. </p>
          </Col>
        </Row>
        <Row style={{color:"black", marginBottom: "50px"}} >
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={outcome} alt="Psychology and Outcomes in Car" />
                <h2>Psychology and Outcomes in Care</h2>
                <p>Here we explore outcomes in a variety of contexts including treatment and non-treatment factors.</p>
          </Col>
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={ear} alt="Psychosocial Consequences of Abnormal Ear" />
                <h2>Psychosocial Consequences of Abnormal Ears</h2>
                <p>For those born with abnormal ears, there are many factors that influence outcomes. Consequently, predicting individual outcomes is challenging. Here we explore the intersection of parent, child and clinician needs in order to maximize decision making. </p>
          </Col>
        </Row>
        <Row style={{color:"black", marginBottom: "50px"}} >
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={imaging} alt="Imaging (fNIRS and fMRI) with Hearing Devices" />
                <h2>Imaging (fNIRS and fMRI) with Hearing Devices</h2>
                <p>This is a collaborative area with Neuroscientist Dr. Jacqueline Cummine. Here we explore the long-term brain changes associated with fitting hearing devices. </p>
          </Col>
          <Col sm={4} style={{margin: "auto"}}>
          <img style={{width: "40%", marginBottom: "20px"}} src={literacy} alt="Understanding and Managing Adult Literacy" />
                <h2>Understanding and Managing Adult Literacy</h2>
                <p>Another area of collaboration with Dr. Cummine (she is the PI). Here we attempt to uncover the differences in brains and behaviours of people with and without reading challenges. Recently, we have started to compare treatment options as well. </p>
          </Col>
        </Row>
        </Container>

        <Bcal />
      </section>

      

     <Footer />
    </div>
  );
};

export default Ideas;
