import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";

import whoWeAre from "./assets/img/who-we-are.jpg";
import whatWeDo from "./assets/img/what-we-do.jpg";
import joinUs from "./assets/img/join-us.jpg";
import ourIdeas from "./assets/img/our-ideas.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

import { UofaBranding } from "./main-components/uofa-branding";
import { Header } from "main-components/header";
import { FooterCom } from "./main-components/footer";
import "./style.css";
import Ellipse2 from "./assets/images/Ellipse 2.svg";
import Ellipse3 from "./assets/images/Ellipse 3.svg";

const Main = (props) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    await axios.post("/api/hearing/visit", { page: "/" });
    document.title = "Rehab Science";
  }, []);

  const handleHearing = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/hearing/");
    }, 2000);
  };

  const handleAppearanceTest = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/appearance/");
    }, 2000);
  };

  const handleLiteracyTest = () => {
    setOpen(true);
    setTimeout(() => {
      window.location = "/literacy/";
    }, 2000);
  };

  const handleIRSMTest = () => {
    setOpen(true);
    setTimeout(() => {
      window.location = "/irsm/";
    }, 2000);
  };

  const handleHearingTestDemo = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/hearing/demo/");
    }, 2000);
  };

  const handleImageProcess = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/imageprocess");
    }, 2000);
  };

  const handleAudiogram = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/audiogram");
    }, 2000);
  };

  const handleHearingTest = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/hearing-test");
    }, 2000);
  };

  const handleDatabase = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/database");
    }, 2000);
  };

  const handleAdmin = () => {
    setOpen(true);
    setTimeout(() => {
      props.history.push("/gui/admin");
    }, 2000);
  };
  return (
    <div className="main">
      <div className="div-2">
        <div className="overlap">
          <img className="ellipse" alt="Ellipse" src={Ellipse3} />
          <div className="branding-section">
            <p className="commonly-used-in-the">
              Audio Experiments & Literacy & Hearing Tests & Image Processing &
              Audiogram & Database & Admin
            </p>
            <div className="text-wrapper-4">
              Rehabilitation Science Evaluation
            </div>
            <img
              className="businessman"
              alt="Businessman"
              src={require("./assets/images/businessman-analyzing-data.png")}
            />
            <button className="button">
              <div className="overlap-group">
                <div className="let-s-go">LET’S GO</div>
              </div>
            </button>
          </div>
          <div className="testimonial-section">
            <img
              className="testimonial"
              alt="Testimonial"
              src={require("./assets/images/Testimonial 3.png")}
            />
            <img
              className="testimonial-2"
              alt="Testimonial"
              src={require("./assets/images/Testimonial 2.png")}
            />
            <img
              className="testimonial-3"
              alt="Testimonial"
              src={require("./assets/images/testimonial-1.png")}
            />
            <div className="text-wrapper-5">Who We Are</div>
          </div>
          <div className="uofa-branding-instance">
            <UofaBranding
              UA={require("./assets/images/ua-1.png")}
              covenant={require("./assets/images/covenant-1.png")}
              irsm={require("./assets/images/irsm-1.png")}
            />
          </div>
        </div>
        <div className="overlap-2">
          <img className="ellipse-2" alt="Ellipse" src={Ellipse2} />
          <div className="hero-section">
            <button className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="learn-more">LEARN MORE</div>
              </div>
            </button>
            <p className="bone-conduction">
              <span className="span">B</span>
              <span className="text-wrapper-6">one </span>
              <span className="span">C</span>
              <span className="text-wrapper-6">
                onduction
                <br />
              </span>
              <span className="span">A</span>
              <span className="text-wrapper-6">
                mplification
                <br />
              </span>
              <span className="span">L</span>
              <span className="text-wrapper-6">aboratory</span>
            </p>
            <img
              className="business-people"
              alt="Business people"
              src={require("./assets/images/business-people-discussing-business-idea.png")}
            />
          </div>
          <div className="header-instance">
            <Header
              LOGOBcalLogoRemovebg={require("./assets/images/bcal-logo-removebg-preview-1.png")}
            />
          </div>
        </div>
        <div className="footer-instance">
          <FooterCom
            img={require("./assets/images/screenshot-20240423-at-9021.png")}
            screenshot={require("./assets/images/screenshot-20240423-at-9031.png")}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
