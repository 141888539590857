import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import drBill from "../assets/img/bill-hodgetts.jpg";
import drJacqueline from "../assets/img/jacqueline-cummine.jpg";
import a from "../assets/img/a.png";
import c from "../assets/img/c.jpg";
import d from "../assets/img/d.png";
import k from "../assets/img/k.png";
import h from "../assets/img/h.png";
import l from "../assets/img/l.png";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import Bcal from "components/Bcal";

const Weare = () => {
  return (
    <div class="app-two">
      <NavBar value="weare" />

      <section
        className="row title-background weare"
        style={{ background: "white" }}
        id="weare"
      >
        <div className="col">
          <h1
            className="main-page-title"
            style={{ color: "rgb(36,118,19)", marginBottom: "100px" }}
          >
            Who We Are
          </h1>
          <p style={{ color: "black" }}>
            While a lab may appear to be under the direction of an individual or
            two, reality is much different. All labs are networks of unique
            people who share in the curiosity of the topics we study. We believe
            good work comes not from top down directives, but rather, from the
            interacting networks of people influencing one another with the
            collective purpose of finding better solutions to the challenges we
            care about. If this is something you would like to be a part of,
            check to see if we have any openings and get in touch!
          </p>
        </div>
        <Container>
          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "50px" }}>
              Current Academic Personnel
            </h2>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "50%", marginBottom: "20px" }}
                src={drBill}
                alt="Professor Bill Hodgetts"
              />
              <h3>Bill Hodgetts, Ph.D, R.Aud</h3>
              <p>
                Principal Investigator, Professor, Communication Sciences &
                Disorders, College of Health Sciences, Faculty of Rehabilitation
                Medicine & Institute for Reconstructive Sciences in Medicine.
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:bill.hodgetts@ualberta.ca"
                >
                  bill.hodgetts@ualberta.ca
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "50%", marginBottom: "20px" }}
                src={drJacqueline}
                alt="Professor Jacqueline Cummine"
              />
              <h3>Jacqueline Cummine, Ph.D</h3>
              <p>
                Co-Investigator, Professor, Communication Sciences & Disorders,
                College of Health Sciences, Faculty of Rehabilitation Medicine{" "}
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:jcummine@ualberta.ca"
                >
                  jcummine@ualberta.ca
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}>
              Research Coordinator
            </h2>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Amberley Ostevik"
              />
              <h3>Amberley Ostevik, M.Sc., R.Aud</h3>
              <p>
                Research Coordinator, Communication Sciences & Disorders,
                College of Health Sciences, Faculty of Rehabilitation Medicine
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:ostevik@ualberta.ca"
                >
                  ostevik@ualberta.ca{" "}
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}>
              Current Ph.D. Graduate Personnel
            </h2>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={c}
                alt="Craig St. Jean"
              />
              <h3>Craig St. Jean, M.S., Ph.D, Candidate</h3>
              <p>
                Communication Sciences & Disorders, College of Health Sciences,
                Faculty of Rehabilitation Medicine
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:crstjean@ualberta.ca"
                >
                  crstjean@ualberta.ca
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={l}
                alt="Lindsay McHutchion"
              />
              <h3>Lindsay McHutchion, M.S., Ph.D Student</h3>
              <p>
                College of Health Sciences, Faculty of Rehabilitation Medicine &
                Institute for Reconstructive Sciences in Medicine.{" "}
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:mchutchi@ualberta.ca "
                >
                  mchutchi@ualberta.ca
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Imaging (fNIRS and fMRI) with Hearing Devices"
              />
              <h3>Alex Gascon, M.S., Ph.D Student</h3>
              <p>
                College of Health Sciences, Faculty of Rehabilitation Medicine &
                Institute for Reconstructive Sciences in Medicine.
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:agascon@ualberta.ca"
                >
                  agascon@ualberta.ca
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={h}
                alt="Understanding and Managing Adult Literacy"
              />
              <h3>
                Syahidatul Huda Binti Mustaffar Kamal (Huda), M.S., Ph.D Student
              </h3>
              <p>
                College of Health Sciences, Faculty of Rehabilitation Medicine
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:huda.mustaffar@ualberta.ca"
                >
                  huda.mustaffar@ualberta.ca
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={k}
                alt="Imaging (fNIRS and fMRI) with Hearing Devices"
              />
              <h3>Kathleen Jones, AuD, RAud, Aud(C), Ph.D. Student</h3>
              <p>
                College of Health Sciences, Faculty of Rehabilitation Medicine
              </p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:kjones@profaudiology.com"
                >
                  kjones@profaudiology.com
                </a>
              </span>
            </Col>
          </Row>

          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}>
              Current M.Sc. Graduate Students
            </h2>
            <Col style={{ margin: "auto" }}>
              {/* <img style={{width: "300px", marginBottom: "20px"}} src={k} alt="Craig St. Jean" /> */}
              <h3>Kristen Mulderrig</h3>
              {/* <p>Communication Sciences & Disorders, College of Health Sciences, Faculty of Rehabilitation Medicine</p> */}
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:mulderri@ualberta.ca"
                >
                  mulderri@ualberta.ca
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              {/* <img style={{width: "300px", marginBottom: "20px"}} src={l} alt="Lindsay McHutchion" /> */}
              <h3>Lindsay Chopiuk</h3>
              {/* <p>College of Health Sciences, Faculty of Rehabilitation Medicine & Institute for Reconstructive Sciences in Medicine. </p> */}
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:chopiuk@ualberta.ca"
                >
                  {" "}
                  chopiuk@ualberta.ca
                </a>
              </span>
            </Col>
          </Row>

          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}> </h2>
            <Col style={{ margin: "auto" }}>
              {/* <img style={{width: "300px", marginBottom: "20px"}} src={k} alt="Craig St. Jean" /> */}
              <h3>Nadia Semenoff </h3>
              {/* <p>Communication Sciences & Disorders, College of Health Sciences, Faculty of Rehabilitation Medicine</p> */}
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:nsemenof@ualberta.ca"
                >
                  nsemenof@ualberta.ca
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              {/* <img style={{width: "300px", marginBottom: "20px"}} src={l} alt="Lindsay McHutchion" /> */}
              <h3>Sarah Sweneya </h3>
              {/* <p>College of Health Sciences, Faculty of Rehabilitation Medicine & Institute for Reconstructive Sciences in Medicine. </p> */}
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:sweneya@ualberta.ca"
                >
                  sweneya@ualberta.ca
                </a>
              </span>
            </Col>
          </Row>

          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}>
              Computer Programmer
            </h2>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={k}
                alt="Qi (Kay) Song"
              />
              <h3>Qi (Kay) Song, Computing Science Student</h3>
              <p>Department of Computing Science, Faculty of Science</p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:qsong@ualberta.ca "
                >
                  qsong@ualberta.ca{" "}
                </a>
              </span>
            </Col>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Yongbin Kim"
              />
              <h3>Yongbin Kim, Computing Science Student</h3>
              <p>Department of Computing Science, Faculty of Science</p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:yongbin2@ualberta.ca "
                >
                  yongbin2@ualberta.ca{" "}
                </a>
              </span>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Gagan Cheema"
              />
              <h3>Gagan Cheema, Computing Science Student</h3>
              <p>Department of Computing Science, Faculty of Science</p>
              <span>
                <a
                  style={{ color: "rgb(36,118,19)" }}
                  href="mailto:gcheema@ualberta.ca "
                >
                  gcheema@ualberta.ca{" "}
                </a>
              </span>
            </Col>
          </Row>

          <Row style={{ color: "black", marginBottom: "70px" }}>
            <h2 style={{ color: "rgb(36,118,19)", marginBottom: "20px" }}>
              Research Affiliates
            </h2>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={d}
                alt="Craig St. Jean"
              />
              <h3>Daniel Aalto, PhD</h3>
              <p>
                Assistant Professor, Communication Sciences & Disorders, College
                of Health Sciences, Faculty of Rehabilitation Medicine &
                Institute for Reconstructive Sciences in Medicine.
              </p>
            </Col>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={k}
                alt="Craig St. Jean"
              />
              <h3>Kelly Nisbet, PhD</h3>
              <p>
                Post Doctoral Fellow, Communication Sciences & Disorders,
                College of Health Sciences, Faculty of Rehabilitation Medicine
              </p>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Amberley Ostevik"
              />
              <h3>Angela Cullum, PhD Candidate</h3>
              <p>
                Communication Sciences & Disorders, College of Health Sciences,
                Faculty of Rehabilitation Medicine
              </p>
            </Col>
          </Row>
          <Row style={{ color: "black", marginBottom: "50px" }}>
            <Col style={{ margin: "auto" }}>
              <img
                style={{ width: "300px", marginBottom: "20px" }}
                src={a}
                alt="Kulpreet Cheema"
              />
              <h3>Kulpreet Cheema, PhD</h3>
              <p>
                Communication Sciences & Disorders, College of Health Sciences,
                Faculty of Rehabilitation Medicine
              </p>
            </Col>
          </Row>
        </Container>

        <Bcal />
      </section>

      <Footer />
    </div>
  );
};

export default Weare;
