import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { P1, P2, P3 } from "assets/fonts";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const GUIStructure = () => {
  const classes = useStyles();
  const [currCollection, setCurrCollection] = useState(null);
  const [name, setName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const history = useHistory();

  useEffect(() => {
    updateLayer({ id: "1" });
  }, []);

  const onAppClick = (obj) => {
    const appName = obj.replace(/ /g, "");
    history.push(`/gui/admin/${appName}`);
  };

  const updateLayer = async (ele) => {
    const doc = await axios.get("/api/gui/collection/" + ele.id);
    setCurrCollection(doc.data);
  };

  const addNewCollection = async () => {
    if (name === "") return window.alert("Name cannot be empty");
    const id = uuidv4();
    await axios.post("/api/gui/collection", {
      id,
      name: name,
      prevCollection: currCollection.id,
    });
    const list = currCollection.list;
    list.push({
      id,
      type: "collection",
      name: name,
    });
    const doc = await axios.put("/api/gui/collection/" + currCollection.id, {
      list,
    });

    setName("");
    updateLayer(doc.data);
  };

  const addNewApp = async () => {
    if (name === "") return window.alert("Name cannot be empty");
    const id = uuidv4().substring(0, 4);
    // create app using name with no space
    await axios.post("/api/gui/app", { id, name: name.replace(/ /g, "") });
    const list = currCollection.list;
    // update collection list app with space
    list.push({
      id,
      type: "app",
      name: name,
    });
    const doc = await axios.put("/api/gui/collection/" + currCollection.id, {
      list,
    });

    setName("");
    updateLayer(doc.data);
  };

  const editElement = async (obj) => {
    if (newName === "") return window.alert("Name cannot be empty");
    await axios.put(`/api/gui/${obj.type}/${obj.id}`, {
      name: newName.replace(/ /g, ""),
    });
    const list = currCollection.list;
    list.map((ele) => {
      if (ele.id === obj.id) {
        ele.name = newName;
      }
    });
    const doc = await axios.put("/api/gui/collection/" + currCollection.id, {
      list,
    });

    setName("");
    updateLayer(doc.data);
  };

  const removeElement = async (obj) => {
    if (!currCollection) return;
    await axios.delete(`/api/gui/${obj.type}/${obj.id}`);
    let list = currCollection.list;
    for (let i = 0; i < list.length; i++) {
      const ele = list[i];
      if (ele.id === obj.id) {
        list = list.slice(0, i).concat(list.slice(i + 1));
        break;
      }
    }
    const doc = await axios.put("/api/gui/collection/" + currCollection.id, {
      list,
    });

    setName("");
    updateLayer(doc.data);
  };

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(to right, #7CA295, #C7D28A)",
        height: window.innerHeight,
        width: window.innerWidth,
      }}
    >
      <div style={{ paddingTop: "10%", paddingLeft: "10%" }}>
        <P1>GUI</P1>
      </div>
      <div className="row" style={{ paddingTop: "5%", paddingLeft: "10%" }}>
        <div className="col">
          <List className={classes.root}>
            <ListItem key="title1">
              <P2>Collection and App</P2>
            </ListItem>
            {currCollection?.list.map((obj) => {
              return (
                <ListItem key={obj.id} button>
                  <ListItemText
                    onClick={
                      obj.type === "app"
                        ? () => onAppClick(obj.name)
                        : () => updateLayer(obj)
                    }
                    primary={obj.name}
                    secondary={obj.type}
                  />
                  <ListItemIcon>
                    <EditIcon
                      style={{ border: "solid", width: "30px", height: "30px" }}
                      color="primary"
                      onClick={() => setEditDialogOpen(obj)}
                    />
                    <CancelIcon
                      style={{ border: "solid", width: "30px", height: "30px" }}
                      color="secondary"
                      onClick={() => setDeleteDialogOpen(obj)}
                    />
                  </ListItemIcon>
                </ListItem>
              );
            })}
            {currCollection?.name !== "main" && (
              <ListItem key="back">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    updateLayer({ id: currCollection.prevCollection })
                  }
                >
                  Back
                </Button>
              </ListItem>
            )}
          </List>
        </div>
        <div className="col">
          <List className={classes.root}>
            <ListItem key="title2">
              <P2>Actions</P2>
            </ListItem>
            <ListItem key="name">
              Name:{" "}
              <TextField
                style={{ marginLeft: 10 }}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </ListItem>
            <ListItem key="collection" button onClick={addNewCollection}>
              <ListItemText primary="Create new collection" />
            </ListItem>
            <ListItem key="application" button onClick={addNewApp}>
              <ListItemText primary="Create new application" />
            </ListItem>
          </List>
        </div>
      </div>
      <Dialog open={!!editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>
          Please add a new name to this {editDialogOpen.type}.
        </DialogTitle>
        <DialogContent>
          New Name:{" "}
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              editElement(editDialogOpen);
              setEditDialogOpen(false);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Are you sure you want to delete this {deleteDialogOpen.type}? It may
          contain sub components.
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              removeElement(deleteDialogOpen);
              setDeleteDialogOpen(false);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GUIStructure;
