import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo2 from "../assets/img/ua-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";

const NavBar = (props) => {
  const [activeLink, setActiveLink] = useState(props.value || "home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar
      expand="lg"
      className={`navbar ${scrolled ? "scrolled" : ""}`}
      style={{ backgroundColor: "rgb(36,118,19)" }}
    >
      <Container style={{ backgroundColor: "rgb(36,118,19)", margin: "auto" }}>
        <Navbar.Brand href="/">
          <img
            className="logo"
            src={logo2}
            alt="University of Alberta Rehab Science logo"
          />
          <h2 style={{ color: "rgb(247,219,14)" }}>
            Faculty of Medicine and Rehabilitation Science
          </h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="/"
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="/#evaluations"
              className={
                activeLink === "evaluations"
                  ? "active navbar-link"
                  : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("evaluations")}
            >
              Rehabilitation Science Evaluations
            </Nav.Link>
            <Nav.Link
              href="/pages/Participate"
              className={
                activeLink === "participate"
                  ? "active navbar-link"
                  : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("participate")}
            >
              Participate In Our Research!
            </Nav.Link>
            <Nav.Link
              href="/pages/Ideas"
              className={
                activeLink === "ideas" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("ideas")}
            >
              These Are Our Ideas
            </Nav.Link>
            <Nav.Link
              href="/pages/Weare"
              className={
                activeLink === "weare" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("weare")}
            >
              Who We Are
            </Nav.Link>
            <Nav.Link
              href="/pages/Wedo"
              className={
                activeLink === "whatwedo" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("whatwedo")}
            >
              What We Do
            </Nav.Link>
            <Nav.Link
              href="/pages/join"
              className={
                activeLink === "join" ? "active navbar-link" : "navbar-link"
              }
              onChange={() => onUpdateActiveLink("join")}
            >
              Join Our Team
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
